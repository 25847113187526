import React, {useState} from "react";
import {alpha} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import EditOffIcon from "@mui/icons-material/EditOff";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import {visuallyHidden} from "@mui/utils";
import {Button} from "@mui/material";
import projectId from "../utils/projectId";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: "key",
    numeric: false,
    disablePadding: false,
    label: "Key"
  },
  {
    id: "correlationId",
    disablePadding: false,
    label: "Correlation Id"
  },
  {
    id: "type",
    disablePadding: false,
    label: "Type"
  },
  {
    id: "attributes",
    disablePadding: false,
    label: "Attributes"
  },
  {
    id: "data",
    disablePadding: false,
    label: "Data"
  },
  {
    id: "id",
    disablePadding: false,
    label: "Id"
  },
  {
    id: "logs",
    numeric: false,
    disablePadding: false,
    label: "Logs"
  },
  {
    id: "time",
    numeric: true,
    disablePadding: false,
    label: "Time"
  }
];

function EnhancedTableHead({onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, editMode}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {editMode && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={() => onSelectAllClick(numSelected > 0 && numSelected <= rowCount)}
              inputProps={{
                "aria-label": "select all desserts"
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = ({numSelected, onResend, onDiscard, onEditChange, editMode}) => {
  return (
    <Toolbar
      sx={{
        pl: {sm: 2},
        pr: {xs: 1, sm: 1},
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{flex: "1 1 100%"}} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{flex: "1 1 100%"}} variant="h6" id="tableTitle" component="div">
          DLX
        </Typography>
      )}

      {editMode ? (
        <>
          <Tooltip title="Cancel Edit">
            <IconButton onClick={onEditChange}>
              <EditOffIcon />
            </IconButton>
          </Tooltip>
          <Tooltip style={{marginLeft: 10}} title="Discard">
            <IconButton disabled={numSelected === 0} onClick={onDiscard} color="error">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip style={{marginLeft: 10}} title="Send">
            <IconButton disabled={numSelected === 0} onClick={onResend} color="info">
              <SendIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Edit">
          <IconButton onClick={onEditChange}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default function EnhancedTable({rows, onDiscard, onResend, onRowSelected}) {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("time");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const pageSize = JSON.parse(localStorage.getItem("pageSize")) || 5;
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (!checked) {
      const newSelecteds = rows
        .sort(getComparator(order, orderBy))
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((n) => n.messageId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, correlationId) => {
    const selectedIndex = selected.indexOf(correlationId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, correlationId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("pageSize", event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (correlationId) => selected.indexOf(correlationId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  return (
    <Box sx={{width: "100%"}}>
      <Paper sx={{width: "100%", mb: 2}}>
        <EnhancedTableToolbar
          editMode={editMode}
          onEditChange={() => {
            setEditMode(!editMode);
            if (editMode) setSelected([]);
          }}
          onDiscard={() => {
            const filteredRows = rows.filter((row) => selected.includes(row.messageId));
            setSelected([]);
            onDiscard(filteredRows);
          }}
          onResend={() => {
            const filteredRows = rows.filter((row) => selected.includes(row.messageId));
            setSelected([]);
            onResend(filteredRows);
          }}
          numSelected={selected?.length}
        />
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
            <EnhancedTableHead
              editMode={editMode}
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {rows
                .sort(getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const isItemSelected = isSelected(row.messageId);

                  return (
                    <TableRow
                      style={{cursor: "pointer"}}
                      hover
                      onClick={(event) => {
                        if (editMode) {
                          handleClick(event, row.messageId);
                        } else {
                          onRowSelected(row.messageId);
                        }
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.messageId}
                      selected={isItemSelected}
                    >
                      {editMode && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": row.messageId
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell>{row.attributes.key}</TableCell>
                      <TableCell>{row.data.correlationId}</TableCell>
                      <TableCell>{row.data.type}</TableCell>
                      <TableCell style={{maxHeight: 50, overflow: "hidden"}}>
                        {JSON.stringify(row.data.attributes ?? {}).substring(0, 150)}
                        {JSON.stringify(row.data.attributes ?? {}).length > 150 ? "..." : ""}
                      </TableCell>
                      <TableCell style={{maxHeight: 50, overflow: "hidden"}}>
                        {JSON.stringify(row.data.data).substring(0, 150)}
                        {JSON.stringify(row.data.data).length > 150 ? "..." : ""}
                      </TableCell>
                      <TableCell>{row.data.id}</TableCell>
                      <TableCell>
                        <Button
                          target="_blank"
                          href={`https://console.cloud.google.com/logs/query;query=resource.type%20%3D%20%22cloud_run_revision%22%0Aresource.labels.location%20%3D%20%22europe-west1%22%0AjsonPayload.correlationId%20%3D%20%22${row.correlationId}%22%0A%20severity%3E%3DDEFAULT;timeRange=PT1H;summaryFields=resource%252Flabels%252Fservice_name,jsonPayload%252FcorrelationId,jsonPayload%252Fkey:false:32:beginning;cursorTimestamp=2021-10-25T13:50:51.568Z?project=${projectId}&cloudshell=false&orgonly=true&supportedpurview=organizationId`}
                        >
                          View Logs
                        </Button>
                      </TableCell>
                      <TableCell align="right">{new Date(row.publishTime).toLocaleString()}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
